export default defineNuxtRouteMiddleware((to) => {
  /**
   * should redirect old urls
   * /Kino-Grevenbroich/Grefi%2520Kino%2520Grevenbroich/vorstellung/140353
   * to
   * /kino/grevenbroich/grefi-kino-grevenbroich/vorstellung/140353
   */
  if (to.path.startsWith('/Kino-')) {
    const parts = to.path.split('/')
    const citySlug = parts[1].toLowerCase().replace('kino-', '')
    const cinemaSlug = decodeURIComponent(decodeURIComponent(parts[2]))
      .toLowerCase()
      .replace(/\s+/g, '-')
    const subPath = parts[3] || ''
    const id = parts[4] || ''

    const newPath = ['kino', citySlug, cinemaSlug, subPath, id]
      .filter(Boolean)
      .join('/')

    if (!subPath && !id) {
      return navigateTo(`/${newPath}/vorstellungen`)
    } else {
      return navigateTo(`/${newPath}`)
    }
  }

  // should redirect en urls from google
  // https://www.kinoheld.de/cinema/muenchen/royal-filmpalast/show/436270
  // to
  // https://www.kinoheld.de/kino/muenchen/royal-filmpalast/vorstellung/436270
  if (to.path.startsWith('/cinema/')) {
    const parts = to.path.split('/')
    const citySlug = parts[2].toLowerCase()
    const cinemaSlug = parts[3].toLowerCase()
    const id = parts[5]

    return navigateTo(`/kino/${citySlug}/${cinemaSlug}/vorstellung/${id}`)
  }

  // should redirect order urls
  // https://www.kinoheld.de/account/order/2405980087168/NzI0Nzc2.MTE2NDg2MTI1OTgw.NTk4MTExMjczODA/?lang=de
  if (to.path.startsWith('/account/order/')) {
    const parts = to.path.split('/')

    return navigateTo(`/konto/bestellung/${parts[3]}/${parts[4]}`)
  }
})

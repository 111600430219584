<template>
  <div id="app-kinoheld">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <ConsentManager />
  </div>
</template>

<script setup lang="ts">
await useAsyncData('app:setup', () => useAppSetup())

defineOptions({
  name: 'AppKinoheld',
})
</script>

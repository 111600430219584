import { default as _91_46_46_46pageSlug_938SQrzo73lBMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/[...pageSlug].vue?macro=true";
import { default as activatewjvQQyduSvMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/activate.vue?macro=true";
import { default as indexHa9Ogx7067Meta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93OdVVtXZrLYMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newHm4Wh8xljaMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlist6ZB8b4lkMdMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/watchlist.vue?macro=true";
import { default as faqSUqGljdc5JMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as indexhnmDoeku8zMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue?macro=true";
import { default as infowPriTeGYIKMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as indexgQz62O1ydBMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue?macro=true";
import { default as _91showGroupSlug_937iLVGfSRMUMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programrPihZQg4txMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shop2H9XTh9ofTMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showName_93fPFuPy4r58Meta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue?macro=true";
import { default as index7MzFzVdNgGMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue?macro=true";
import { default as _91showSlug_93yATts8vu9fMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as vouchersdXzk9EpNgrMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93U4clnjRF9NMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indext5sxfPcfXSMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as _91citySlug_93PMjDqDZSLvMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug].vue?macro=true";
import { default as cinemasMq3HXCmwM2Meta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinemas.vue?macro=true";
import { default as citiesF719Mj6I0ZMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cities.vue?macro=true";
import { default as indexKT6FD84UHzMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/index.vue?macro=true";
import { default as indexhAvodnydreMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as _91movieSlug_93E8lizSnUgsMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexzZgmh7ReDbMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93uE2Y0QSTiLMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genresCdTyvISVh2Meta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexi6C3GNLucIMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/index.vue?macro=true";
import { default as indexjWfyxs3gHOMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/person/[personSlug]/index.vue?macro=true";
import { default as _91personSlug_93vOoOLJagYpMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsPU2FykicvxMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/persons.vue?macro=true";
import { default as searchx5aaISGBMZMeta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/search.vue?macro=true";
import { default as vouchersUwc3LEJXL3Meta } from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: _91_46_46_46pageSlug_938SQrzo73lBMeta?.name ?? "pageSlug___en",
    path: _91_46_46_46pageSlug_938SQrzo73lBMeta?.path ?? "/en/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_938SQrzo73lBMeta || {},
    alias: _91_46_46_46pageSlug_938SQrzo73lBMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_938SQrzo73lBMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46pageSlug_938SQrzo73lBMeta?.name ?? "pageSlug___de",
    path: _91_46_46_46pageSlug_938SQrzo73lBMeta?.path ?? "/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_938SQrzo73lBMeta || {},
    alias: _91_46_46_46pageSlug_938SQrzo73lBMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_938SQrzo73lBMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46pageSlug_938SQrzo73lBMeta?.name ?? "pageSlug___es",
    path: _91_46_46_46pageSlug_938SQrzo73lBMeta?.path ?? "/es/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_938SQrzo73lBMeta || {},
    alias: _91_46_46_46pageSlug_938SQrzo73lBMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_938SQrzo73lBMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: activatewjvQQyduSvMeta?.name ?? "account-activate___en",
    path: activatewjvQQyduSvMeta?.path ?? "/en/account/activate",
    meta: activatewjvQQyduSvMeta || {},
    alias: activatewjvQQyduSvMeta?.alias || [],
    redirect: activatewjvQQyduSvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: activatewjvQQyduSvMeta?.name ?? "account-activate___de",
    path: activatewjvQQyduSvMeta?.path ?? "/konto/aktivieren",
    meta: activatewjvQQyduSvMeta || {},
    alias: activatewjvQQyduSvMeta?.alias || [],
    redirect: activatewjvQQyduSvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: activatewjvQQyduSvMeta?.name ?? "account-activate___es",
    path: activatewjvQQyduSvMeta?.path ?? "/es/cuenta/activar",
    meta: activatewjvQQyduSvMeta || {},
    alias: activatewjvQQyduSvMeta?.alias || [],
    redirect: activatewjvQQyduSvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: indexHa9Ogx7067Meta?.name ?? "account___en",
    path: indexHa9Ogx7067Meta?.path ?? "/en/account",
    meta: indexHa9Ogx7067Meta || {},
    alias: indexHa9Ogx7067Meta?.alias || [],
    redirect: indexHa9Ogx7067Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexHa9Ogx7067Meta?.name ?? "account___de",
    path: indexHa9Ogx7067Meta?.path ?? "/konto",
    meta: indexHa9Ogx7067Meta || {},
    alias: indexHa9Ogx7067Meta?.alias || [],
    redirect: indexHa9Ogx7067Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexHa9Ogx7067Meta?.name ?? "account___es",
    path: indexHa9Ogx7067Meta?.path ?? "/es/cuenta",
    meta: indexHa9Ogx7067Meta || {},
    alias: indexHa9Ogx7067Meta?.alias || [],
    redirect: indexHa9Ogx7067Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93OdVVtXZrLYMeta?.name ?? "account-order-orderNumber-securityKey___en",
    path: _91securityKey_93OdVVtXZrLYMeta?.path ?? "/en/account/order/:orderNumber/:securityKey",
    meta: _91securityKey_93OdVVtXZrLYMeta || {},
    alias: _91securityKey_93OdVVtXZrLYMeta?.alias || [],
    redirect: _91securityKey_93OdVVtXZrLYMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93OdVVtXZrLYMeta?.name ?? "account-order-orderNumber-securityKey___de",
    path: _91securityKey_93OdVVtXZrLYMeta?.path ?? "/konto/bestellung/:orderNumber/:securityKey",
    meta: _91securityKey_93OdVVtXZrLYMeta || {},
    alias: _91securityKey_93OdVVtXZrLYMeta?.alias || [],
    redirect: _91securityKey_93OdVVtXZrLYMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93OdVVtXZrLYMeta?.name ?? "account-order-orderNumber-securityKey___es",
    path: _91securityKey_93OdVVtXZrLYMeta?.path ?? "/es/cuenta/compra/:orderNumber/:securityKey",
    meta: _91securityKey_93OdVVtXZrLYMeta || {},
    alias: _91securityKey_93OdVVtXZrLYMeta?.alias || [],
    redirect: _91securityKey_93OdVVtXZrLYMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: newHm4Wh8xljaMeta?.name ?? "account-password-new___en",
    path: newHm4Wh8xljaMeta?.path ?? "/en/account/password/new",
    meta: newHm4Wh8xljaMeta || {},
    alias: newHm4Wh8xljaMeta?.alias || [],
    redirect: newHm4Wh8xljaMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: newHm4Wh8xljaMeta?.name ?? "account-password-new___de",
    path: newHm4Wh8xljaMeta?.path ?? "/konto/passwort/neu",
    meta: newHm4Wh8xljaMeta || {},
    alias: newHm4Wh8xljaMeta?.alias || [],
    redirect: newHm4Wh8xljaMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: newHm4Wh8xljaMeta?.name ?? "account-password-new___es",
    path: newHm4Wh8xljaMeta?.path ?? "/es/cuenta/contrasena/nueva",
    meta: newHm4Wh8xljaMeta || {},
    alias: newHm4Wh8xljaMeta?.alias || [],
    redirect: newHm4Wh8xljaMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: watchlist6ZB8b4lkMdMeta?.name ?? "account-watchlist___en",
    path: watchlist6ZB8b4lkMdMeta?.path ?? "/en/account/watchlist",
    meta: watchlist6ZB8b4lkMdMeta || {},
    alias: watchlist6ZB8b4lkMdMeta?.alias || [],
    redirect: watchlist6ZB8b4lkMdMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: watchlist6ZB8b4lkMdMeta?.name ?? "account-watchlist___de",
    path: watchlist6ZB8b4lkMdMeta?.path ?? "/konto/merkliste",
    meta: watchlist6ZB8b4lkMdMeta || {},
    alias: watchlist6ZB8b4lkMdMeta?.alias || [],
    redirect: watchlist6ZB8b4lkMdMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: watchlist6ZB8b4lkMdMeta?.name ?? "account-watchlist___es",
    path: watchlist6ZB8b4lkMdMeta?.path ?? "/es/cuenta/marcadores",
    meta: watchlist6ZB8b4lkMdMeta || {},
    alias: watchlist6ZB8b4lkMdMeta?.alias || [],
    redirect: watchlist6ZB8b4lkMdMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    path: _91citySlug_93PMjDqDZSLvMeta?.path ?? "/en/cinema/:citySlug",
    children: [
  {
    path: _91cinemaSlug_93U4clnjRF9NMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug",
    children: [
  {
    name: faqSUqGljdc5JMeta?.name ?? "cinema-citySlug-cinemaSlug-faq___en",
    path: faqSUqGljdc5JMeta?.path ?? "faq",
    meta: faqSUqGljdc5JMeta || {},
    alias: faqSUqGljdc5JMeta?.alias || [],
    redirect: faqSUqGljdc5JMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: indexhnmDoeku8zMeta?.name ?? "cinema-citySlug-cinemaSlug___en",
    path: indexhnmDoeku8zMeta?.path ?? "",
    meta: indexhnmDoeku8zMeta || {},
    alias: indexhnmDoeku8zMeta?.alias || [],
    redirect: indexhnmDoeku8zMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: infowPriTeGYIKMeta?.name ?? "cinema-citySlug-cinemaSlug-info___en",
    path: infowPriTeGYIKMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/info",
    meta: infowPriTeGYIKMeta || {},
    alias: infowPriTeGYIKMeta?.alias || [],
    redirect: infowPriTeGYIKMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    path: _91showGroupSlug_937iLVGfSRMUMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    children: [
  {
    name: indexgQz62O1ydBMeta?.name ?? "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: indexgQz62O1ydBMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    meta: indexgQz62O1ydBMeta || {},
    alias: indexgQz62O1ydBMeta?.alias || [],
    redirect: indexgQz62O1ydBMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showGroupSlug_937iLVGfSRMUMeta?.name ?? undefined,
    meta: _91showGroupSlug_937iLVGfSRMUMeta || {},
    alias: _91showGroupSlug_937iLVGfSRMUMeta?.alias || [],
    redirect: _91showGroupSlug_937iLVGfSRMUMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: programrPihZQg4txMeta?.name ?? "cinema-citySlug-cinemaSlug-program___en",
    path: programrPihZQg4txMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/program",
    meta: programrPihZQg4txMeta || {},
    alias: programrPihZQg4txMeta?.alias || [],
    redirect: programrPihZQg4txMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: shop2H9XTh9ofTMeta?.name ?? "cinema-citySlug-cinemaSlug-shop___en",
    path: shop2H9XTh9ofTMeta?.path ?? "shop",
    meta: shop2H9XTh9ofTMeta || {},
    alias: shop2H9XTh9ofTMeta?.alias || [],
    redirect: shop2H9XTh9ofTMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_93yATts8vu9fMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    children: [
  {
    name: _91showName_93fPFuPy4r58Meta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: _91showName_93fPFuPy4r58Meta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    meta: _91showName_93fPFuPy4r58Meta || {},
    alias: _91showName_93fPFuPy4r58Meta?.alias || [],
    redirect: _91showName_93fPFuPy4r58Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: index7MzFzVdNgGMeta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: index7MzFzVdNgGMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    meta: index7MzFzVdNgGMeta || {},
    alias: index7MzFzVdNgGMeta?.alias || [],
    redirect: index7MzFzVdNgGMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_93yATts8vu9fMeta?.name ?? undefined,
    meta: _91showSlug_93yATts8vu9fMeta || {},
    alias: _91showSlug_93yATts8vu9fMeta?.alias || [],
    redirect: _91showSlug_93yATts8vu9fMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m)
  },
  {
    name: vouchersdXzk9EpNgrMeta?.name ?? "cinema-citySlug-cinemaSlug-vouchers___en",
    path: vouchersdXzk9EpNgrMeta?.path ?? "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    meta: vouchersdXzk9EpNgrMeta || {},
    alias: vouchersdXzk9EpNgrMeta?.alias || [],
    redirect: vouchersdXzk9EpNgrMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
],
    name: _91cinemaSlug_93U4clnjRF9NMeta?.name ?? undefined,
    meta: _91cinemaSlug_93U4clnjRF9NMeta || {},
    alias: _91cinemaSlug_93U4clnjRF9NMeta?.alias || [],
    redirect: _91cinemaSlug_93U4clnjRF9NMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m)
  },
  {
    name: indext5sxfPcfXSMeta?.name ?? "cinema-citySlug___en",
    path: indext5sxfPcfXSMeta?.path ?? "",
    meta: indext5sxfPcfXSMeta || {},
    alias: indext5sxfPcfXSMeta?.alias || [],
    redirect: indext5sxfPcfXSMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91citySlug_93PMjDqDZSLvMeta?.name ?? undefined,
    meta: _91citySlug_93PMjDqDZSLvMeta || {},
    alias: _91citySlug_93PMjDqDZSLvMeta?.alias || [],
    redirect: _91citySlug_93PMjDqDZSLvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m)
  },
  {
    path: _91citySlug_93PMjDqDZSLvMeta?.path ?? "/kino/:citySlug",
    children: [
  {
    path: _91cinemaSlug_93U4clnjRF9NMeta?.path ?? "/kino/:citySlug/:cinemaSlug",
    children: [
  {
    name: faqSUqGljdc5JMeta?.name ?? "cinema-citySlug-cinemaSlug-faq___de",
    path: faqSUqGljdc5JMeta?.path ?? "faq",
    meta: faqSUqGljdc5JMeta || {},
    alias: faqSUqGljdc5JMeta?.alias || [],
    redirect: faqSUqGljdc5JMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: indexhnmDoeku8zMeta?.name ?? "cinema-citySlug-cinemaSlug___de",
    path: indexhnmDoeku8zMeta?.path ?? "",
    meta: indexhnmDoeku8zMeta || {},
    alias: indexhnmDoeku8zMeta?.alias || [],
    redirect: indexhnmDoeku8zMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: infowPriTeGYIKMeta?.name ?? "cinema-citySlug-cinemaSlug-info___de",
    path: infowPriTeGYIKMeta?.path ?? "/kino/:citySlug/:cinemaSlug/info",
    meta: infowPriTeGYIKMeta || {},
    alias: infowPriTeGYIKMeta?.alias || [],
    redirect: infowPriTeGYIKMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    path: _91showGroupSlug_937iLVGfSRMUMeta?.path ?? "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    children: [
  {
    name: indexgQz62O1ydBMeta?.name ?? "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: indexgQz62O1ydBMeta?.path ?? "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    meta: indexgQz62O1ydBMeta || {},
    alias: indexgQz62O1ydBMeta?.alias || [],
    redirect: indexgQz62O1ydBMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showGroupSlug_937iLVGfSRMUMeta?.name ?? undefined,
    meta: _91showGroupSlug_937iLVGfSRMUMeta || {},
    alias: _91showGroupSlug_937iLVGfSRMUMeta?.alias || [],
    redirect: _91showGroupSlug_937iLVGfSRMUMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: programrPihZQg4txMeta?.name ?? "cinema-citySlug-cinemaSlug-program___de",
    path: programrPihZQg4txMeta?.path ?? "/kino/:citySlug/:cinemaSlug/vorstellungen",
    meta: programrPihZQg4txMeta || {},
    alias: programrPihZQg4txMeta?.alias || [],
    redirect: programrPihZQg4txMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: shop2H9XTh9ofTMeta?.name ?? "cinema-citySlug-cinemaSlug-shop___de",
    path: shop2H9XTh9ofTMeta?.path ?? "shop",
    meta: shop2H9XTh9ofTMeta || {},
    alias: shop2H9XTh9ofTMeta?.alias || [],
    redirect: shop2H9XTh9ofTMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_93yATts8vu9fMeta?.path ?? "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    children: [
  {
    name: _91showName_93fPFuPy4r58Meta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: _91showName_93fPFuPy4r58Meta?.path ?? "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    meta: _91showName_93fPFuPy4r58Meta || {},
    alias: _91showName_93fPFuPy4r58Meta?.alias || [],
    redirect: _91showName_93fPFuPy4r58Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: index7MzFzVdNgGMeta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: index7MzFzVdNgGMeta?.path ?? "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    meta: index7MzFzVdNgGMeta || {},
    alias: index7MzFzVdNgGMeta?.alias || [],
    redirect: index7MzFzVdNgGMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_93yATts8vu9fMeta?.name ?? undefined,
    meta: _91showSlug_93yATts8vu9fMeta || {},
    alias: _91showSlug_93yATts8vu9fMeta?.alias || [],
    redirect: _91showSlug_93yATts8vu9fMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m)
  },
  {
    name: vouchersdXzk9EpNgrMeta?.name ?? "cinema-citySlug-cinemaSlug-vouchers___de",
    path: vouchersdXzk9EpNgrMeta?.path ?? "/kino/:citySlug/:cinemaSlug/gutscheine",
    meta: vouchersdXzk9EpNgrMeta || {},
    alias: vouchersdXzk9EpNgrMeta?.alias || [],
    redirect: vouchersdXzk9EpNgrMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
],
    name: _91cinemaSlug_93U4clnjRF9NMeta?.name ?? undefined,
    meta: _91cinemaSlug_93U4clnjRF9NMeta || {},
    alias: _91cinemaSlug_93U4clnjRF9NMeta?.alias || [],
    redirect: _91cinemaSlug_93U4clnjRF9NMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m)
  },
  {
    name: indext5sxfPcfXSMeta?.name ?? "cinema-citySlug___de",
    path: indext5sxfPcfXSMeta?.path ?? "",
    meta: indext5sxfPcfXSMeta || {},
    alias: indext5sxfPcfXSMeta?.alias || [],
    redirect: indext5sxfPcfXSMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91citySlug_93PMjDqDZSLvMeta?.name ?? undefined,
    meta: _91citySlug_93PMjDqDZSLvMeta || {},
    alias: _91citySlug_93PMjDqDZSLvMeta?.alias || [],
    redirect: _91citySlug_93PMjDqDZSLvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m)
  },
  {
    path: _91citySlug_93PMjDqDZSLvMeta?.path ?? "/es/cine/:citySlug",
    children: [
  {
    path: _91cinemaSlug_93U4clnjRF9NMeta?.path ?? "/es/cine/:citySlug/:cinemaSlug",
    children: [
  {
    name: faqSUqGljdc5JMeta?.name ?? "cinema-citySlug-cinemaSlug-faq___es",
    path: faqSUqGljdc5JMeta?.path ?? "faq",
    meta: faqSUqGljdc5JMeta || {},
    alias: faqSUqGljdc5JMeta?.alias || [],
    redirect: faqSUqGljdc5JMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: indexhnmDoeku8zMeta?.name ?? "cinema-citySlug-cinemaSlug___es",
    path: indexhnmDoeku8zMeta?.path ?? "",
    meta: indexhnmDoeku8zMeta || {},
    alias: indexhnmDoeku8zMeta?.alias || [],
    redirect: indexhnmDoeku8zMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: infowPriTeGYIKMeta?.name ?? "cinema-citySlug-cinemaSlug-info___es",
    path: infowPriTeGYIKMeta?.path ?? "/es/cine/:citySlug/:cinemaSlug/informacion",
    meta: infowPriTeGYIKMeta || {},
    alias: infowPriTeGYIKMeta?.alias || [],
    redirect: infowPriTeGYIKMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    path: _91showGroupSlug_937iLVGfSRMUMeta?.path ?? "movie/:showGroupSlug()",
    children: [
  {
    name: indexgQz62O1ydBMeta?.name ?? "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: indexgQz62O1ydBMeta?.path ?? "",
    meta: indexgQz62O1ydBMeta || {},
    alias: indexgQz62O1ydBMeta?.alias || [],
    redirect: indexgQz62O1ydBMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showGroupSlug_937iLVGfSRMUMeta?.name ?? undefined,
    meta: _91showGroupSlug_937iLVGfSRMUMeta || {},
    alias: _91showGroupSlug_937iLVGfSRMUMeta?.alias || [],
    redirect: _91showGroupSlug_937iLVGfSRMUMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: programrPihZQg4txMeta?.name ?? "cinema-citySlug-cinemaSlug-program___es",
    path: programrPihZQg4txMeta?.path ?? "/es/cine/:citySlug/:cinemaSlug/sesiones",
    meta: programrPihZQg4txMeta || {},
    alias: programrPihZQg4txMeta?.alias || [],
    redirect: programrPihZQg4txMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: shop2H9XTh9ofTMeta?.name ?? "cinema-citySlug-cinemaSlug-shop___es",
    path: shop2H9XTh9ofTMeta?.path ?? "shop",
    meta: shop2H9XTh9ofTMeta || {},
    alias: shop2H9XTh9ofTMeta?.alias || [],
    redirect: shop2H9XTh9ofTMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_93yATts8vu9fMeta?.path ?? "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    children: [
  {
    name: _91showName_93fPFuPy4r58Meta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: _91showName_93fPFuPy4r58Meta?.path ?? "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    meta: _91showName_93fPFuPy4r58Meta || {},
    alias: _91showName_93fPFuPy4r58Meta?.alias || [],
    redirect: _91showName_93fPFuPy4r58Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: index7MzFzVdNgGMeta?.name ?? "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: index7MzFzVdNgGMeta?.path ?? "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    meta: index7MzFzVdNgGMeta || {},
    alias: index7MzFzVdNgGMeta?.alias || [],
    redirect: index7MzFzVdNgGMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_93yATts8vu9fMeta?.name ?? undefined,
    meta: _91showSlug_93yATts8vu9fMeta || {},
    alias: _91showSlug_93yATts8vu9fMeta?.alias || [],
    redirect: _91showSlug_93yATts8vu9fMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m)
  },
  {
    name: vouchersdXzk9EpNgrMeta?.name ?? "cinema-citySlug-cinemaSlug-vouchers___es",
    path: vouchersdXzk9EpNgrMeta?.path ?? "/es/cine/:citySlug/:cinemaSlug/codigos",
    meta: vouchersdXzk9EpNgrMeta || {},
    alias: vouchersdXzk9EpNgrMeta?.alias || [],
    redirect: vouchersdXzk9EpNgrMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
],
    name: _91cinemaSlug_93U4clnjRF9NMeta?.name ?? undefined,
    meta: _91cinemaSlug_93U4clnjRF9NMeta || {},
    alias: _91cinemaSlug_93U4clnjRF9NMeta?.alias || [],
    redirect: _91cinemaSlug_93U4clnjRF9NMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m)
  },
  {
    name: indext5sxfPcfXSMeta?.name ?? "cinema-citySlug___es",
    path: indext5sxfPcfXSMeta?.path ?? "",
    meta: indext5sxfPcfXSMeta || {},
    alias: indext5sxfPcfXSMeta?.alias || [],
    redirect: indext5sxfPcfXSMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91citySlug_93PMjDqDZSLvMeta?.name ?? undefined,
    meta: _91citySlug_93PMjDqDZSLvMeta || {},
    alias: _91citySlug_93PMjDqDZSLvMeta?.alias || [],
    redirect: _91citySlug_93PMjDqDZSLvMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m)
  },
  {
    name: cinemasMq3HXCmwM2Meta?.name ?? "cinemas___en",
    path: cinemasMq3HXCmwM2Meta?.path ?? "/en/cinemas",
    meta: cinemasMq3HXCmwM2Meta || {},
    alias: cinemasMq3HXCmwM2Meta?.alias || [],
    redirect: cinemasMq3HXCmwM2Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: cinemasMq3HXCmwM2Meta?.name ?? "cinemas___de",
    path: cinemasMq3HXCmwM2Meta?.path ?? "/kinos",
    meta: cinemasMq3HXCmwM2Meta || {},
    alias: cinemasMq3HXCmwM2Meta?.alias || [],
    redirect: cinemasMq3HXCmwM2Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: cinemasMq3HXCmwM2Meta?.name ?? "cinemas___es",
    path: cinemasMq3HXCmwM2Meta?.path ?? "/es/cines",
    meta: cinemasMq3HXCmwM2Meta || {},
    alias: cinemasMq3HXCmwM2Meta?.alias || [],
    redirect: cinemasMq3HXCmwM2Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: citiesF719Mj6I0ZMeta?.name ?? "cities___en",
    path: citiesF719Mj6I0ZMeta?.path ?? "/en/cities",
    meta: citiesF719Mj6I0ZMeta || {},
    alias: citiesF719Mj6I0ZMeta?.alias || [],
    redirect: citiesF719Mj6I0ZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesF719Mj6I0ZMeta?.name ?? "cities___de",
    path: citiesF719Mj6I0ZMeta?.path ?? "/staedte",
    meta: citiesF719Mj6I0ZMeta || {},
    alias: citiesF719Mj6I0ZMeta?.alias || [],
    redirect: citiesF719Mj6I0ZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesF719Mj6I0ZMeta?.name ?? "cities___es",
    path: citiesF719Mj6I0ZMeta?.path ?? "/es/ciudades",
    meta: citiesF719Mj6I0ZMeta || {},
    alias: citiesF719Mj6I0ZMeta?.alias || [],
    redirect: citiesF719Mj6I0ZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: indexKT6FD84UHzMeta?.name ?? "index___en",
    path: indexKT6FD84UHzMeta?.path ?? "/en",
    meta: indexKT6FD84UHzMeta || {},
    alias: indexKT6FD84UHzMeta?.alias || [],
    redirect: indexKT6FD84UHzMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexKT6FD84UHzMeta?.name ?? "index___de",
    path: indexKT6FD84UHzMeta?.path ?? "/",
    meta: indexKT6FD84UHzMeta || {},
    alias: indexKT6FD84UHzMeta?.alias || [],
    redirect: indexKT6FD84UHzMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexKT6FD84UHzMeta?.name ?? "index___es",
    path: indexKT6FD84UHzMeta?.path ?? "/es",
    meta: indexKT6FD84UHzMeta || {},
    alias: indexKT6FD84UHzMeta?.alias || [],
    redirect: indexKT6FD84UHzMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_93E8lizSnUgsMeta?.path ?? "/en/movie/:movieSlug",
    children: [
  {
    name: indexhAvodnydreMeta?.name ?? "movie-movieSlug___en",
    path: indexhAvodnydreMeta?.path ?? "",
    meta: indexhAvodnydreMeta || {},
    alias: indexhAvodnydreMeta?.alias || [],
    redirect: indexhAvodnydreMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_93E8lizSnUgsMeta?.name ?? undefined,
    meta: _91movieSlug_93E8lizSnUgsMeta || {},
    alias: _91movieSlug_93E8lizSnUgsMeta?.alias || [],
    redirect: _91movieSlug_93E8lizSnUgsMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_93E8lizSnUgsMeta?.path ?? "/film/:movieSlug",
    children: [
  {
    name: indexhAvodnydreMeta?.name ?? "movie-movieSlug___de",
    path: indexhAvodnydreMeta?.path ?? "",
    meta: indexhAvodnydreMeta || {},
    alias: indexhAvodnydreMeta?.alias || [],
    redirect: indexhAvodnydreMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_93E8lizSnUgsMeta?.name ?? undefined,
    meta: _91movieSlug_93E8lizSnUgsMeta || {},
    alias: _91movieSlug_93E8lizSnUgsMeta?.alias || [],
    redirect: _91movieSlug_93E8lizSnUgsMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_93E8lizSnUgsMeta?.path ?? "/es/pelicula/:movieSlug",
    children: [
  {
    name: indexhAvodnydreMeta?.name ?? "movie-movieSlug___es",
    path: indexhAvodnydreMeta?.path ?? "",
    meta: indexhAvodnydreMeta || {},
    alias: indexhAvodnydreMeta?.alias || [],
    redirect: indexhAvodnydreMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_93E8lizSnUgsMeta?.name ?? undefined,
    meta: _91movieSlug_93E8lizSnUgsMeta || {},
    alias: _91movieSlug_93E8lizSnUgsMeta?.alias || [],
    redirect: _91movieSlug_93E8lizSnUgsMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    path: _91genreSlug_93uE2Y0QSTiLMeta?.path ?? "/en/movies/genre/:genreSlug",
    children: [
  {
    name: indexzZgmh7ReDbMeta?.name ?? "movies-genre-genreSlug___en",
    path: indexzZgmh7ReDbMeta?.path ?? "",
    meta: indexzZgmh7ReDbMeta || {},
    alias: indexzZgmh7ReDbMeta?.alias || [],
    redirect: indexzZgmh7ReDbMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91genreSlug_93uE2Y0QSTiLMeta?.name ?? undefined,
    meta: _91genreSlug_93uE2Y0QSTiLMeta || {},
    alias: _91genreSlug_93uE2Y0QSTiLMeta?.alias || [],
    redirect: _91genreSlug_93uE2Y0QSTiLMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m)
  },
  {
    path: _91genreSlug_93uE2Y0QSTiLMeta?.path ?? "/filme/genre/:genreSlug",
    children: [
  {
    name: indexzZgmh7ReDbMeta?.name ?? "movies-genre-genreSlug___de",
    path: indexzZgmh7ReDbMeta?.path ?? "",
    meta: indexzZgmh7ReDbMeta || {},
    alias: indexzZgmh7ReDbMeta?.alias || [],
    redirect: indexzZgmh7ReDbMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91genreSlug_93uE2Y0QSTiLMeta?.name ?? undefined,
    meta: _91genreSlug_93uE2Y0QSTiLMeta || {},
    alias: _91genreSlug_93uE2Y0QSTiLMeta?.alias || [],
    redirect: _91genreSlug_93uE2Y0QSTiLMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m)
  },
  {
    path: _91genreSlug_93uE2Y0QSTiLMeta?.path ?? "/es/peliculas/generos/:genreSlug",
    children: [
  {
    name: indexzZgmh7ReDbMeta?.name ?? "movies-genre-genreSlug___es",
    path: indexzZgmh7ReDbMeta?.path ?? "",
    meta: indexzZgmh7ReDbMeta || {},
    alias: indexzZgmh7ReDbMeta?.alias || [],
    redirect: indexzZgmh7ReDbMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91genreSlug_93uE2Y0QSTiLMeta?.name ?? undefined,
    meta: _91genreSlug_93uE2Y0QSTiLMeta || {},
    alias: _91genreSlug_93uE2Y0QSTiLMeta?.alias || [],
    redirect: _91genreSlug_93uE2Y0QSTiLMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m)
  },
  {
    name: genresCdTyvISVh2Meta?.name ?? "movies-genres___en",
    path: genresCdTyvISVh2Meta?.path ?? "/en/movies/genres",
    meta: genresCdTyvISVh2Meta || {},
    alias: genresCdTyvISVh2Meta?.alias || [],
    redirect: genresCdTyvISVh2Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: genresCdTyvISVh2Meta?.name ?? "movies-genres___de",
    path: genresCdTyvISVh2Meta?.path ?? "/filme/genres",
    meta: genresCdTyvISVh2Meta || {},
    alias: genresCdTyvISVh2Meta?.alias || [],
    redirect: genresCdTyvISVh2Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: genresCdTyvISVh2Meta?.name ?? "movies-genres___es",
    path: genresCdTyvISVh2Meta?.path ?? "/es/peliculas/generos",
    meta: genresCdTyvISVh2Meta || {},
    alias: genresCdTyvISVh2Meta?.alias || [],
    redirect: genresCdTyvISVh2Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: indexi6C3GNLucIMeta?.name ?? "movies___en",
    path: indexi6C3GNLucIMeta?.path ?? "/en/movies",
    meta: indexi6C3GNLucIMeta || {},
    alias: indexi6C3GNLucIMeta?.alias || [],
    redirect: indexi6C3GNLucIMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: indexi6C3GNLucIMeta?.name ?? "movies___de",
    path: indexi6C3GNLucIMeta?.path ?? "/filme",
    meta: indexi6C3GNLucIMeta || {},
    alias: indexi6C3GNLucIMeta?.alias || [],
    redirect: indexi6C3GNLucIMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: indexi6C3GNLucIMeta?.name ?? "movies___es",
    path: indexi6C3GNLucIMeta?.path ?? "/es/peliculas",
    meta: indexi6C3GNLucIMeta || {},
    alias: indexi6C3GNLucIMeta?.alias || [],
    redirect: indexi6C3GNLucIMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    path: _91personSlug_93vOoOLJagYpMeta?.path ?? "/en/person/:personSlug",
    children: [
  {
    name: indexjWfyxs3gHOMeta?.name ?? "person-personSlug___en",
    path: indexjWfyxs3gHOMeta?.path ?? "",
    meta: indexjWfyxs3gHOMeta || {},
    alias: indexjWfyxs3gHOMeta?.alias || [],
    redirect: indexjWfyxs3gHOMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91personSlug_93vOoOLJagYpMeta?.name ?? undefined,
    meta: _91personSlug_93vOoOLJagYpMeta || {},
    alias: _91personSlug_93vOoOLJagYpMeta?.alias || [],
    redirect: _91personSlug_93vOoOLJagYpMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    path: _91personSlug_93vOoOLJagYpMeta?.path ?? "/person/:personSlug",
    children: [
  {
    name: indexjWfyxs3gHOMeta?.name ?? "person-personSlug___de",
    path: indexjWfyxs3gHOMeta?.path ?? "",
    meta: indexjWfyxs3gHOMeta || {},
    alias: indexjWfyxs3gHOMeta?.alias || [],
    redirect: indexjWfyxs3gHOMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91personSlug_93vOoOLJagYpMeta?.name ?? undefined,
    meta: _91personSlug_93vOoOLJagYpMeta || {},
    alias: _91personSlug_93vOoOLJagYpMeta?.alias || [],
    redirect: _91personSlug_93vOoOLJagYpMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    path: _91personSlug_93vOoOLJagYpMeta?.path ?? "/es/persona/:personSlug",
    children: [
  {
    name: indexjWfyxs3gHOMeta?.name ?? "person-personSlug___es",
    path: indexjWfyxs3gHOMeta?.path ?? "",
    meta: indexjWfyxs3gHOMeta || {},
    alias: indexjWfyxs3gHOMeta?.alias || [],
    redirect: indexjWfyxs3gHOMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91personSlug_93vOoOLJagYpMeta?.name ?? undefined,
    meta: _91personSlug_93vOoOLJagYpMeta || {},
    alias: _91personSlug_93vOoOLJagYpMeta?.alias || [],
    redirect: _91personSlug_93vOoOLJagYpMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: personsPU2FykicvxMeta?.name ?? "persons___en",
    path: personsPU2FykicvxMeta?.path ?? "/en/persons",
    meta: personsPU2FykicvxMeta || {},
    alias: personsPU2FykicvxMeta?.alias || [],
    redirect: personsPU2FykicvxMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: personsPU2FykicvxMeta?.name ?? "persons___de",
    path: personsPU2FykicvxMeta?.path ?? "/personen",
    meta: personsPU2FykicvxMeta || {},
    alias: personsPU2FykicvxMeta?.alias || [],
    redirect: personsPU2FykicvxMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: personsPU2FykicvxMeta?.name ?? "persons___es",
    path: personsPU2FykicvxMeta?.path ?? "/es/personas",
    meta: personsPU2FykicvxMeta || {},
    alias: personsPU2FykicvxMeta?.alias || [],
    redirect: personsPU2FykicvxMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: searchx5aaISGBMZMeta?.name ?? "search___en",
    path: searchx5aaISGBMZMeta?.path ?? "/en/search",
    meta: searchx5aaISGBMZMeta || {},
    alias: searchx5aaISGBMZMeta?.alias || [],
    redirect: searchx5aaISGBMZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchx5aaISGBMZMeta?.name ?? "search___de",
    path: searchx5aaISGBMZMeta?.path ?? "/suche",
    meta: searchx5aaISGBMZMeta || {},
    alias: searchx5aaISGBMZMeta?.alias || [],
    redirect: searchx5aaISGBMZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchx5aaISGBMZMeta?.name ?? "search___es",
    path: searchx5aaISGBMZMeta?.path ?? "/es/buscar",
    meta: searchx5aaISGBMZMeta || {},
    alias: searchx5aaISGBMZMeta?.alias || [],
    redirect: searchx5aaISGBMZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: vouchersUwc3LEJXL3Meta?.name ?? "vouchers___en",
    path: vouchersUwc3LEJXL3Meta?.path ?? "/en/vouchers",
    meta: vouchersUwc3LEJXL3Meta || {},
    alias: vouchersUwc3LEJXL3Meta?.alias || [],
    redirect: vouchersUwc3LEJXL3Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: vouchersUwc3LEJXL3Meta?.name ?? "vouchers___de",
    path: vouchersUwc3LEJXL3Meta?.path ?? "/gutscheine",
    meta: vouchersUwc3LEJXL3Meta || {},
    alias: vouchersUwc3LEJXL3Meta?.alias || [],
    redirect: vouchersUwc3LEJXL3Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: vouchersUwc3LEJXL3Meta?.name ?? "vouchers___es",
    path: vouchersUwc3LEJXL3Meta?.path ?? "/es/codigos",
    meta: vouchersUwc3LEJXL3Meta || {},
    alias: vouchersUwc3LEJXL3Meta?.alias || [],
    redirect: vouchersUwc3LEJXL3Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  }
]
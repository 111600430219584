import revive_payload_client_XXu4BP1hG7 from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/node_modules/.pnpm/nuxt@3.9.0_eslint@8.57.0_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CfYB8xGFYT from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/node_modules/.pnpm/nuxt@3.9.0_eslint@8.57.0_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0R6WOZifre from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/node_modules/.pnpm/nuxt@3.9.0_eslint@8.57.0_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_u2NbETYx4N from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/node_modules/.pnpm/nuxt@3.9.0_eslint@8.57.0_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_2bwQIjEgat from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/node_modules/.pnpm/nuxt@3.9.0_eslint@8.57.0_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/apps/kinoheld/.nuxt/components.plugin.mjs";
import prefetch_client_JF0AydgkkQ from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/node_modules/.pnpm/nuxt@3.9.0_eslint@8.57.0_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_DhJ4RXZjb4 from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/node_modules/.pnpm/nuxt-graphql-client@0.2.31_graphql-tag@2.12.6_typescript@5.3.3/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import composition_gcHGUdkyO1 from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_vue-router@4.2.5_vue@3.4.19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_HcSmU1aB0o from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_vue-router@4.2.5_vue@3.4.19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_p7qFCUWJeG from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/node_modules/.pnpm/nuxt@3.9.0_eslint@8.57.0_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/kinoheld/live/2024-08-22_18-08-03_d825110/layers/base/plugins/sentry.client.ts";
export default [
  revive_payload_client_XXu4BP1hG7,
  unhead_CfYB8xGFYT,
  router_0R6WOZifre,
  payload_client_u2NbETYx4N,
  check_outdated_build_client_2bwQIjEgat,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JF0AydgkkQ,
  plugin_DhJ4RXZjb4,
  composition_gcHGUdkyO1,
  i18n_HcSmU1aB0o,
  chunk_reload_client_p7qFCUWJeG,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV
]